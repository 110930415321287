$body-color: #ffffff;

$header-image: "assets/headerimage.png";
$device-color: "assets/black.png";
$watch-color: "assets/watch_black.png";

$accent-color: #1d63ea;

$header-title-color: #ffffff;
$app-title-color: #ffffff;
$app-subtitle-color: #ffffff;
$app-description-color: #ffffff;

$feature-title-color: #000000;
$feature-text-color: #666666;
$footer-text-color: #666666;

$header_color: #000000;
$header_transparency: 0.1;

$image-overlay-color: #363b3d;
$image-overlay-transparency: 0.4;

$feature-icons-foreground-color: #1d63ea;
$feature-icons-background-color: #e6e6e6;

$social-icons-foreground-color: #666666;
$social-icons-background-color: #e6e6e6;

@import 
    "base",
    "layout",
    "github-markdown"